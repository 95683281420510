.container {
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 100%;
    width: 100%;
    justify-content: center;

    .countdownContainer {
        display: flex;
        height: 100%;
        filter: drop-shadow(0px 0px 20px var(--color-secondary));
        flex-direction: row;
        align-items: center;
    }
}

.container .innerContainer {
    width: 90px;
    height: 100px;
    display: flex;
    justify-content: center;
}

.container h2 {
    -webkit-text-stroke: 2px var(--color-secondary);
    text-align: center;
    color: transparent;
    font-size: 3.5em;
    position: absolute;
}

@media (--desktop-viewport) {
    .container {
        h2 {
            font-size: 5.5em;
            -webkit-text-stroke: 3px var(--color-secondary);
        }

        .innerContainer {
            width: 160px;
        }
    }
}
