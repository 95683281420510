.container {
    width: 100%;
    height: 100%;
    .cardContainer {
        width: 100%;
        height: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }
}
