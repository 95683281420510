.container {
    min-height: 100vh;
    height: fit-content;
    width: 100%;
    padding-top: var(--border-main-top);

    &.borderTopDisabled {
        padding-top: 0;
    }
}
