.container {
    position: fixed;
    z-index: 1;
    width: fit-content;
    height: calc(var(--border-main) * 2);
    min-height: 100px;

    .contentContainer {
        height: 100%;
        width: 100px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 10px var(--border-main);
    }

    .background {
        pointer-events: none;
        position: fixed;
        top: 0;

        /*background: linear-gradient(
            180deg,
            var(--color-neutral) 0%,
            var(--color-neutral-25) 40%,
            transparent 50%,
            transparent 100%
        );*/
        background-color: var(--color-neutral);
        background: linear-gradient(180deg, var(--color-neutral), transparent 40%);
        -webkit-mask: -webkit-linear-gradient(
            270deg,
            hsl(0, 0%, 0%) 0%,
            hsla(0, 0%, 0%, 0.991) 7.8%,
            hsla(0, 0%, 0%, 0.966) 14.4%,
            hsla(0, 0%, 0%, 0.927) 20.1%,
            hsla(0, 0%, 0%, 0.875) 25.1%,
            hsla(0, 0%, 0%, 0.812) 29.7%,
            hsla(0, 0%, 0%, 0.74) 34%,
            hsla(0, 0%, 0%, 0.661) 38.4%,
            hsla(0, 0%, 0%, 0.578) 42.9%,
            hsla(0, 0%, 0%, 0.49) 48%,
            hsla(0, 0%, 0%, 0.401) 53.6%,
            hsla(0, 0%, 0%, 0.313) 60.2%,
            hsla(0, 0%, 0%, 0.227) 67.9%,
            hsla(0, 0%, 0%, 0.145) 77%,
            hsla(0, 0%, 0%, 0.068) 87.6%,
            hsla(0, 0%, 0%, 0) 100%
        );
        backdrop-filter: blur(20px) brightness(0.9);
        width: 100%;
        height: 500px;
    }
}
